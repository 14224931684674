import { er } from "@fullcalendar/core/internal-common";
import { errors } from "common/errors";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

interface Options<T, V> {
    onError?: (error: unknown) => void;
    onSuccess?: (data: T) => void;
    retry?: number;
    retryDelay?: number;
    debounce?: number;
    initState?: T;
    recallArguments?: V;
    enableAddPage?: boolean;
    ignoreError?: boolean;
}
export function useHandleApiCall<T = unknown, V extends any[] = any[]>(
    cb: (...v: V) => Promise<T>,
    options?: Options<T, V>,
) {

    const [recallArguments, setRecallArguments] = useState<string>(options?.recallArguments ? JSON.stringify(options.recallArguments) : '');

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T | null>(options?.initState || null);
    const [called, setCalled] = useState<boolean>(false);
    const handleCB = useCallback(async (...v: V) => {
        try {
            setCalled(true);
            setLoading(true);
            // @ts-ignore
            const response = await cb(v[0], v[1], v[2], v[3], v[4], v[5], v[6], v[7], v[8], v[9]);
            options?.onSuccess?.(response);
            if (!options?.enableAddPage) {
                setData(response);
            } else {
                if (Array.isArray(response)) {
                    // @ts-ignore
                    setData(prevData => prevData ? [...prevData, ...response] : response);
                } else if (response instanceof Object) {
                    // @ts-ignore
                    if (response?.data && response?.count) {
                        // @ts-ignore
                        setData(prev => ({
                            // @ts-ignore
                            count: response.count,
                            // @ts-ignore
                            data: [...(prev?.data ?? []), ...response.data]
                        }));
                    }
                }
            }
            setLoading(false);
            return response;
        } catch (error) {
            // @ts-ignore
            if (error?.response?.data?.code) {
                // @ts-ignore
                if (errors[error.response.data.code]) {
                    // @ts-ignore
                    toast.error(errors[error.response.data.code]);
                }
            }
            options?.onError?.(error);
            setLoading(false);
            if (!options?.ignoreError)
                throw error;
        } finally {
            setLoading(false);
        }
    }, [cb, options]);

    useEffect(() => {
        const newRecallArguments = JSON.stringify(options?.recallArguments);
        if (options?.recallArguments) {
            handleCB(...options.recallArguments);
            setRecallArguments(newRecallArguments);
        }
    }, []);

    useEffect(() => {
        const newRecallArguments = JSON.stringify(options?.recallArguments);
        if (options?.recallArguments && newRecallArguments !== recallArguments) {
            handleCB(...options.recallArguments);
            setRecallArguments(newRecallArguments);
            // @ts-ignore
        }
    }, [handleCB, options?.recallArguments, recallArguments]);

    return {
        loading,
        data,
        called,
        refetch: handleCB
    };
}

