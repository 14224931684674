import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, List, Row } from 'reactstrap';
import BreadCrumb from '../../../../../Components/Common/BreadCrumb';

// Import React FilePond
import { FilePond } from 'react-filepond';
// Import FilePond styles
import { FilePondFile } from 'filepond';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import { useFormik } from 'formik';
import * as Yup from "yup";


import { CustomButton } from 'Components/UI/CustomButton';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import FlatPicker from "react-flatpickr";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { GetContract } from 'service/contracts';
import { GetCurrencies } from 'service/currencies';
import { GetOwnerPaymentMethods } from 'service/paymentMethods';
import { PaymentMethodMap, PaymentMethodType } from 'types/api/paymentMethod';
import { ICreatePayment, PaymentStatus } from 'types/api/payments';



const formInitValue: ICreatePayment = {
  amount: 0,
  contract: "",
  description: "",
  from: "",
  to: "",
  paymentMethod: PaymentMethodType.INTERNATIONAL_BANK_TRANSFER,
  proof: new File([], ""),
  status: PaymentStatus.PENDING,
  paymentDate: new Date()
}

interface PaymentTemplateProps {
  amount: number;
  currencySymbol: string;
  contract: string;
  onPaymentSubmit: (data: ICreatePayment) => void;
  loading?: boolean;
}

const PaymentTemplate = (v: PaymentTemplateProps) => {
  document.title = "Pago directo al arrendador | Partarentals";
  const navigate = useNavigate()
  const [files, setFiles] = useState<File[]>([]);
  const [filesPreview, setFilesPreview] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<any>();
  const handleGetContract = useHandleApiCall(GetContract, {
    recallArguments: [v.contract ?? ""]
  })

  const [modal_center, setmodal_center] = useState<boolean>(false);

  const handlePaymentValidation = useFormik<ICreatePayment>({
    enableReinitialize: true,
    initialValues: formInitValue,
    onSubmit: () => handleCreatePayment(),
    validationSchema: Yup.object<ICreatePayment>({
      amount: Yup.number().required("Este campo es requerido"),
      contract: Yup.string().required("Este campo es requerido"),
      description: Yup.string().required("Este campo es requerido"),
      from: Yup.string().required("Este campo es requerido"),
      to: Yup.string().required("Este campo es requerido"),
      paymentMethod: Yup.string().required("Este campo es requerido"),
      status: Yup.string().required("Este campo es requerido"),
    }),
  })

  const handleOwnerPaymentMethods = useHandleApiCall(GetOwnerPaymentMethods, {
    initState: [],
    recallArguments: [{
      ownerId: handleGetContract?.data?.owner?._id ?? ""
    }],
    ignoreError: true,
    onSuccess: (data) => {
      if (data[0].type)
        handlePaymentValidation.setFieldValue('paymentMethod', data.filter(el => el.isActive)[0].type)
    }
  })

  const handleCurrencies = useHandleApiCall(GetCurrencies, {
    recallArguments: [],
  });

  function tog_success_payment() {
    setmodal_center(!modal_center);
  }

  const availableColumnOwnerPaymentMethods = useMemo(() => {
    return !!handleOwnerPaymentMethods.data?.filter(el => el.isActive).filter(el => el.type !== PaymentMethodType.CASH).length
  }, [handleOwnerPaymentMethods.data])

  const handleAcceptedFiles = useCallback((newFiles: FilePondFile[]) => {
    if (newFiles.length === files.length) return
    Promise.all(newFiles.map(el => el.file.arrayBuffer())).then((buffers) => {
      setSelectedFiles(buffers.map((buffer, index) => {
        return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
      }))

      setFiles(buffers.map((buffer, index) => {
        return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
      }))
      setPreviewImages(buffers.map(el => URL.createObjectURL(new Blob([el]))));
    })
  }, [files.length])
  useEffect(() => {
    function array_equal(arr1: string[], arr2: string[]) {
      if ((Array.isArray(arr1) && Array.isArray(arr2)) === false) return false;
      // @ts-ignore
      return JSON.stringify([...new Set(arr1.flat().sort())]) === JSON.stringify([...new Set(arr2.flat().sort())]);
    }

    if (!array_equal(files.map(el => el.name), filesPreview.map(el => el.name))) {
      setFilesPreview(files.map(el => el));
    }
  }, [files, filesPreview])

  const handleReorderFiles = useCallback(async (newFiles: FilePondFile[]) => {
    let buffers = await Promise.all(newFiles.map(el => el.file.arrayBuffer()))

    setSelectedFiles(buffers.map((buffer, index) => {
      return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
    }))
    setFiles(buffers.map((buffer, index) => {
      return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
    }))
    setPreviewImages(buffers.map(el => URL.createObjectURL(new Blob([el]))));
  }, [])

  const handleCreatePayment: () => Promise<void> = useCallback(async (): Promise<void> => {
    try {
      if (files.length === 0) {
        toast.error("Debe subir un comprobante de pago")
        return
      }
      const data = {
        ...handlePaymentValidation.values,
        proof: files[0],
        contract: handleGetContract.data?._id ?? "",
        from: handleGetContract.data?.tenant._id ?? "",
        to: handleGetContract.data?.owner._id ?? "",
        status: PaymentStatus.PENDING
      }
      v.onPaymentSubmit(data)
    } catch (error) {
      toast.error("Error al crear el pago")
    }
  }, [files, handleGetContract.data?._id, handleGetContract.data?.owner._id, handleGetContract.data?.tenant._id, handlePaymentValidation.values, v])


  useEffect(() => {
    handlePaymentValidation.setValues(prev => ({
      ...prev,
      from: handleGetContract.data?.tenant._id ?? "",
      to: handleGetContract.data?.owner._id ?? "",
      contract: handleGetContract.data?._id ?? "",
      amount: v.amount ?? 0
    }))
  }, [handleGetContract.data, v.amount])



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Pago directo" pageTitle="Pages" />
          <Row>
            {availableColumnOwnerPaymentMethods && <Col md={4}>
              <Card>
                <CardBody>
                  {(() => {
                    const paymentMethods = handleOwnerPaymentMethods?.data?.find(el => el.type === PaymentMethodType.INTERNATIONAL_BANK_TRANSFER)
                    if (!paymentMethods) return null
                    return <>
                      <div className="">
                        <h4 className=''>Transferencia internacional:</h4>
                        <div>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Titular de la cuenta:</span> {paymentMethods.details.accountName}</p>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Banco:</span> {paymentMethods.details.bankName}</p>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Número de cuenta:</span> {paymentMethods.details.accountNumber}</p>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>ABA Routing Number:</span> {paymentMethods.details.swiftCode}</p>
                        </div>
                      </div>
                      <hr style={{
                        marginRight: "8px",
                        marginLeft: "8px"
                      }} />
                    </>
                  })()}
                  {(() => {
                    const paymentMethods = handleOwnerPaymentMethods?.data?.find(el => el.type === PaymentMethodType.NATIONAL_BANK_TRANSFER)
                    if (!paymentMethods) return null
                    return <>
                      <div>
                        <h4 className=''>Transferencia nacional:</h4>
                        <div>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Titular de la cuenta:</span> {paymentMethods.details.accountName}</p>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Banco:</span> {paymentMethods.details.bankName}</p>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Número de cuenta:</span> {paymentMethods.details.accountNumber}</p>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Cedula de la cuenta:</span> {paymentMethods.details.accountDocumentId}</p>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Moneda de la cuenta:</span> {handleCurrencies.data?.find(el => el?._id === paymentMethods?.details?.currency)?.nombre}</p>
                        </div>
                      </div>
                      <hr style={{
                        marginRight: "8px",
                        marginLeft: "8px"
                      }} />
                    </>
                  })()}
                  {
                    (() => {
                      const paymentMethods = handleOwnerPaymentMethods?.data?.find(el => el.type === PaymentMethodType.PAYPAL)
                      if (!paymentMethods) return null
                      return <>
                        <Col xs={12}>
                          <div>
                            <div className="p-1 live-preview">
                              <div className="table-responsive">
                                <h4 className='fw-bold fs-16'>Pago electrónico:</h4>
                                <p className=' mb-1'>Realiza tu pago a través de los siguientes métodos de pago electrónico:</p>
                                <div className='fw-bold'>
                                  <p className='mb-1 '>Paypal: {paymentMethods.details.email}</p>
                                  {/* <p className='mb-1 '>Binance: 123456789</p>
                            <p className='mb-1 '>Skrill: deazevedoalexander@gmail.com</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <hr style={{
                          marginRight: "8px",
                          marginLeft: "8px"
                        }} />
                      </>
                    })()
                  }
                  {
                    (() => {
                      const paymentMethods = handleOwnerPaymentMethods?.data?.find(el => el.type === PaymentMethodType.SINPE)
                      if (!paymentMethods) return null
                      return <div className="">
                        <h4 className=''>SINPE Móvil:</h4>
                        <div>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Titular de la cuenta:</span> {paymentMethods.details.accountName}</p>
                          <p className='mb-0 parrafo-cuentas'><span className='fw-semibold'>Teléfono:</span> {paymentMethods.details.phoneNumber}</p>
                        </div>
                      </div>
                    })()
                  }
                </CardBody>
              </Card>
            </Col>
            }
            <Col md={availableColumnOwnerPaymentMethods ? 8 : 12}>
              <Card>
                <CardHeader className="align-items-center d-flex justify-content-between">
                  <h4 className="card-title mb-0 flex-grow-1">Reportar pago</h4>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handlePaymentValidation.handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <div className='mb-2'>
                          <Label htmlFor="paymentDate" className="form-label">Contrato</Label>
                          <Input
                            name='contract'
                            id='contract'
                            className="form-control"
                            disabled={true}
                            value={handleGetContract.data?.contractCode}
                          />

                        </div>
                      </Col>
                      <Col md={6}>
                        <div className='mb-2'>
                          <Label htmlFor="paymentDate" className="form-label">Fecha del pago</Label>
                          <FlatPicker
                            name='paymentDate'
                            id='paymentDate'
                            className="form-control"
                            options={{
                              dateFormat: "d M, Y",
                              maxDate: new Date().setHours(23, 59, 59, 999),
                            }}
                            onChange={(date: any) => {
                              handlePaymentValidation.setFieldValue("paymentDate", date[0].toISOString())
                            }}
                            value={handlePaymentValidation.values.paymentDate}
                          />

                        </div>
                      </Col>
                      <Col md={6}>
                        <div className='mb-2'>
                          <Label htmlFor="exampleInputdate" className="form-label">Método de pago</Label>
                          <Select
                            options={(handleOwnerPaymentMethods.data?.filter(el => el.isActive) ?? []).map(el => ({
                              label: PaymentMethodMap[el.type],
                              value: el.type
                            }))}
                            onChange={(el: any) => handlePaymentValidation.setFieldValue("paymentMethod", el?.value)}
                            value={(handleOwnerPaymentMethods.data ?? []).map(el => ({
                              label: PaymentMethodMap[el.type],
                              value: el.type
                            })).find(el => el.value === handlePaymentValidation.values.paymentMethod)}
                            placeHolder="Seleccione un método de pago"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className='mb-4'>
                          <Label htmlFor="amount" className="form-label">Monto pagado</Label>
                          <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">{v.currencySymbol}</span>
                            <Input
                              type="number"
                              className="form-control"
                              name="amount"
                              id="amount"
                              aria-label="Amount (to the nearest dollar)"
                              onChange={handlePaymentValidation.handleChange}
                              value={handlePaymentValidation.values.amount}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <p>Subir comprobante de pago</p>
                      </Col>
                    </Row>

                    <Row className=''>
                      <Col md={12}>
                        <FilePond
                          files={filesPreview}
                          onupdatefiles={handleAcceptedFiles}
                          allowMultiple={true}
                          onreorderfiles={handleReorderFiles}
                          maxFiles={1}
                          name="files"
                          className="filepond filepond-input-multiple "
                          allowReorder={true}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} className='d-flex justify-content-end'>
                        <CustomButton loading={v.loading} className='btn btn-success w-100-mobile' onClick={handleCreatePayment}>
                          Enviar comprobante
                        </CustomButton>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container >
      </div >
    </React.Fragment >

  );
};

export default PaymentTemplate;