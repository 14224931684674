import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, List, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';

// Import React FilePond
import { FilePond } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useFormik } from 'formik';
import { FilePondFile } from 'filepond';
import * as Yup from "yup";


import MaestroIcon from '../../../../assets/images/icons/maestro-icon.png'
import MastercardIcon from '../../../../assets/images/icons/mastercard-icon.png'
import VisaIcon from '../../../../assets/images/icons/visa-icon.png'
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { GetContract } from 'service/contracts';
import { GetOwnerPaymentMethods } from 'service/paymentMethods';
import { PaymentMethodMap, PaymentMethodType } from 'types/api/paymentMethod';
import Select from 'react-select';
import { CreatePayment } from 'service/payments';
import { ICreatePayment, PaymentStatus } from 'types/api/payments';
import FlatPicker from "react-flatpickr";
import { toast } from 'react-toastify';
import moment from 'moment';
import PaymentTemplate from './template/DirectPayment';

const ContractPayment = () => {
  document.title = "Pago directo | Partarentals";
  const navigate = useNavigate()
  const { _id: contractId } = useParams<{ _id: string }>();
  const handleGetContract = useHandleApiCall(GetContract, {
    recallArguments: [contractId ?? ""]
  })

  const handleCreatePaymentCall = useHandleApiCall(CreatePayment)
  const [modal_center, set_modal_center] = useState<boolean>(false);

  const tog_success_payment = () => set_modal_center(prev => !prev);
  const tog_awaiting_payment = () => set_modal_awaiting(prev => !prev);


  const [modal_awaiting, set_modal_awaiting] = useState<boolean>(false);

  const handleCreatePayment = useCallback(async (v: ICreatePayment) => {
    try {
      await handleCreatePaymentCall.refetch(v)
      tog_awaiting_payment()
      toast.success("Pago creado correctamente")
      navigate("/payment-history")
    } catch (error) {
      toast.error("Error al crear el pago")
    }
  }, [handleCreatePaymentCall, navigate, tog_awaiting_payment])


  return (
    <React.Fragment>
      <PaymentTemplate loading={handleCreatePaymentCall.loading} onPaymentSubmit={handleCreatePayment} currencySymbol={handleGetContract.data?.currency.simbolo ?? ''} amount={handleGetContract.data?.agreedPrice ?? 0} contract={contractId ?? ""} />

      {/* Vertically Centered */}
      < Modal
        isOpen={modal_center}
        toggle={() => {
          tog_success_payment();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="bx bx-party display-4 text-success"></i>
          <div className="mt-4">
            <h4 className="mb-3 fw-bold">¡Felicidades! Su pago ha sido recibido.</h4>
            <p className="text-muted mb-4">El contrato entrará en vigencia el dia <span className='fw-bold'>{new Date(handleGetContract.data?.startDate ?? 0).toLocaleDateString()}</span></p>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => set_modal_center(false)}>Cerrar</Button>
            </div>
          </div>
        </ModalBody>
      </ Modal>

      {/* Vertically Centered */}
      < Modal Modal
        isOpen={modal_awaiting}
        toggle={() => {
          tog_awaiting_payment();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="bx bx-time-five display-4 text-success"></i>
          <div className="mt-4">
            <h4 className="mb-3 fw-bold">Su pago está pendiente de aprobación</h4>

            <p className="text-muted mb-4">Una vez aprobado por el propietario su contrato entrará en vigencia el dia <span className='fw-bold'>{moment(handleGetContract.data?.startDate ?? 0).format("DD/MM/YYYY")}</span></p>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => set_modal_awaiting(false)}>Cerrar</Button>
            </div>
          </div>
        </ModalBody>
      </ Modal>

    </React.Fragment >
  );
};

export default ContractPayment;